declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		Atlas: {
			chat: {
				[key: string]: (...args: unknown[]) => void
			}
			call: (command: string, ...args: unknown[]) => void
		}
	}
}

const useAtlasSo = () => {
	return {
		open: () => {
			window.Atlas.chat.openWindow()
		},
		showButton: () => {
			window.Atlas.chat.showBubble()
		},
		hideButton: () => {
			window.Atlas.chat.hideBubble()
		},
		showNewChat: ({
			message,
		}: {
			message?: string
		}) => {
			window.Atlas.call('showNewChat', {
				text: message,
			})
		},
		start: () => {
			window.Atlas.call('start')
		},
		stop: () => {
			window.Atlas.call('stop')
		},
	}
}

export default useAtlasSo
