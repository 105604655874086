'use client'

import { CircleHelpIcon } from 'lucide-react'

import useAtlasSo from '~/hooks/use-atlas-so'
import { Button } from '~/ui/shadcn/ui/button'

const HelpButton = () => {
	const atlasSo = useAtlasSo()

	return (
		<Button
			variant="secondary"
			size="sm"
			className="flex flex-row items-center justify-center gap-1"
			onClick={() => {
				atlasSo.open()
			}}
		>
			<CircleHelpIcon className="size-4" />
			Help
		</Button>
	)
}

export default HelpButton
